import React, { useEffect, useRef } from "react";
import { StyledScroller } from "./style";
import SimpleBar from "simplebar";
import ResizeObserver from "resize-observer-polyfill";

export default ({ mobile, children }) => {
  const scrollerEl = useRef(null);

  useEffect(() => {
    window.ResizeObserver = ResizeObserver;
  }, []);

  useEffect(() => {
    if ((mobile && window.innerWidth <= 1024) || !mobile) {
      new SimpleBar(scrollerEl.current);
    }
  }, [scrollerEl]);
  return <StyledScroller ref={scrollerEl}>{children}</StyledScroller>;
};
